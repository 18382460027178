import styled from 'styled-components';

import ButtonLink from './ButtonLink';
import SanityImageComponent from './SanityImageComponent';

import { ExternalContent } from '../types';

const StyledContentPreviewCard = styled('article')`
  display: flex;
  flex-flow: row;
  padding-bottom: 32px;

  figure {
    margin: 8px 0;
  }

  figure img {
    display: none !important;

    @media (min-width: 768px) {
      display: block !important;
      border-radius: 4px;
      width: 200px;
    }
  }
`;

const StyledContentPreviewSummary = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 768px) {
    margin-right: 16px;
  }

  h3 {
    margin-top: 0.25rem;
  }

  p {
    margin-top: 0;
  }
`;

interface Props {
  externalContent: ExternalContent;
}

const ExternalContentPreviewCard = ({ externalContent }: Props) => {
  return (
    <StyledContentPreviewCard>
      <StyledContentPreviewSummary>
        <h3>{externalContent.title}</h3>
        <p>{externalContent.summary}</p>
        <ButtonLink
          href={externalContent.url}
          text={'Read more'}
          target="_blank"
          rel="noindex nofollow"
          externalLink
        />
      </StyledContentPreviewSummary>
      {externalContent.image && (
        <SanityImageComponent value={externalContent.image} isInline={false} />
      )}
    </StyledContentPreviewCard>
  );
};

export default ExternalContentPreviewCard;
