import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Person } from '../types';
import SanityImageComponent from './SanityImageComponent';
import SanityPortableText from './SanityPortableText';

const StyledTeamMemberPreviewCard = styled('article')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  border-radius: 4px;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    width: 33%;
  }

  a {
    padding: 4px;
  }

  img {
    max-width: 192px;
    max-height: 233px;
    border-radius: 4px;
  }

  p {
    font-size: 1rem;
    margin: 4px 0;
  }
`;

interface Props {
  teamMember: Person;
  pagePath: string;
}

const TeamMemberPreviewCard = ({ teamMember, pagePath }: Props) => {
  return (
    <StyledTeamMemberPreviewCard>
      <Link to={pagePath} className="no-underline">
        <SanityImageComponent
          value={teamMember.image}
          isInline={false}
          className="full-width-image"
        />
        <SanityPortableText content={teamMember.titleBlock} />
      </Link>
    </StyledTeamMemberPreviewCard>
  );
};

export default TeamMemberPreviewCard;
