import { BoxArrowUpRight } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Colors from '../colors';

const StyledButtonLink = styled(Link)<{ alignment: string }>`
  display: flex;
  align-self: ${({ alignment }) => alignment};
  background: ${Colors.darkTransparent};
  color: ${Colors.black};
  border-radius: 4px;
  padding: 8px;
  text-decoration: none;
  width: fit-content;

  :hover,
  :focus {
    color: ${Colors.orange};
  }

  svg {
    padding-top: 4px;
    padding-left: 8px;
  }
`;

interface Props {
  href: string;
  text: string;
  alignment?: 'flex-start' | 'flex-end';
  externalLink?: boolean;
  target?: '_blank' | undefined;
  rel?: 'noindex nofollow' | undefined;
}

const ButtonLink = ({
  href,
  text,
  alignment = 'flex-start',
  externalLink = false,
  target = undefined,
  rel = undefined,
}: Props) => {
  return (
    <StyledButtonLink to={href} alignment={alignment} target={target} rel={rel}>
      {text}
      {externalLink && <BoxArrowUpRight textAnchor="text" size={16} />}
    </StyledButtonLink>
  );
};

export default ButtonLink;
