import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import SanityImageComponent from '../components/SanityImageComponent';
import SanityPortableText from '../components/SanityPortableText';
import { CenteredContentContainer } from '../components/Containers';
import { redirectSlugs } from '../components/HiddenRedirectLinks';
import Loader from '../components/Loader';

import NotFoundPage from './NotFoundPage';

import { NewsArticle } from '../types';

import { getImageUrl, GetNewsArticle } from '../utils/sanity';

import logo from '../assets/img/mi_circle.webp';

const StyledAcknowledgementsContent = styled('div')`
  margin: 1rem 0;
  opacity: 0.75;

  p {
    margin-block: 2rem;
    font-size: 1rem;
  }
`;

const NewsArticlePage = () => {
  const { slug } = useParams();
  const [newsArticle, setNewsArticle] = useState<NewsArticle | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (slug) {
      GetNewsArticle(redirectSlugs[slug] ?? slug).then((article) => {
        setNewsArticle(article);
        setLoading(false);
      });
    }
  }, [slug]);

  return newsArticle ? (
    <CenteredContentContainer>
      <Helmet>
        <title>{`${newsArticle.title} - Mars Institute`}</title>
        <meta property="og:title" content={`${newsArticle.title} - Mars Institute`} />
        <meta
          property="og:url"
          content={`https://marsinstitute.no/news/${newsArticle.slug.current}`}
        />
        <meta name="description" content={newsArticle.summary} />
        <meta property="og:description" content={newsArticle.summary} />
        <meta
          property="og:image"
          content={newsArticle.mainImage ? getImageUrl(newsArticle.mainImage) : logo}
        />
        <meta
          name="twitter:card"
          content={newsArticle.mainImage ? 'summary_large_image' : 'summary'}
        />
      </Helmet>

      <article>
        <h1>{newsArticle.title}</h1>

        {newsArticle.mainImage && (
          <SanityImageComponent value={newsArticle.mainImage} isInline={false} />
        )}

        <SanityPortableText content={newsArticle.body} />

        <StyledAcknowledgementsContent>
          {/* 'About Mars Institute, SETI' etc. */}
          <SanityPortableText content={newsArticle.acknowledgements} />
        </StyledAcknowledgementsContent>
      </article>
    </CenteredContentContainer>
  ) : loading ? (
    <Loader />
  ) : (
    <NotFoundPage />
  );
};

export default NewsArticlePage;
