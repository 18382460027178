import { hydrate, render } from 'react-dom';

import App from './App';

import reportWebVitals from './reportWebVitals';

import './assets/styles/index.css';

const rootElement = document.getElementById('root');

// react-snap stuff
if (rootElement?.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
