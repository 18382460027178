class Colors {
  static readonly light = 'whitesmoke';
  static readonly dark = '#212529';
  static readonly darkTransparent = 'rgba(33, 37, 41, 0.1);';
  static readonly orange = '#f2a60c';
  static readonly white = '#ffffff';
  static readonly black = '#000000';
}

export default Colors;
