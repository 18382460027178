import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { CenteredContentContainer } from '../components/Containers';
import Loader from '../components/Loader';
import ExternalContentPreviewCard from '../components/ExternalContentPreviewCard';
import SanityPortableText from '../components/SanityPortableText';

import { useCommonInformationContext } from '../contexts/commonInformationContext';

import { ExternalContent } from '../types';

import { GetExternalContent } from '../utils/sanity';

import logo from '../assets/img/mi_circle.webp';

const StudentInformationListPage = () => {
  const [externalContent, setExternalContent] = useState<Array<ExternalContent> | undefined>();
  const informationContext = useCommonInformationContext();

  useEffect(() => {
    GetExternalContent().then((content) => setExternalContent(content));
  }, []);

  return (
    <CenteredContentContainer>
      <Helmet>
        <title>Outreach - Mars Institute</title>
        <meta property="og:title" content="Outreach - Mars Institute" />
        <meta property="og:url" content="https://marsinstitute.no/outreach" />
        <meta
          name="description"
          content="The Mars Institute is an international non-governmental organization created with the goals of advancing the scientific study and exploration of Mars, 
            conducting peer-reviewed research, and educating the public about Mars exploration."
        />
        <meta
          property="og:description"
          content="The Mars Institute is an international non-governmental organization created with the goals of advancing the scientific study and exploration of Mars, 
            conducting peer-reviewed research, and educating the public about Mars exploration."
        />
        <meta property="og:image" content={logo} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <article>
        <h1>Outreach</h1>
        {informationContext.informationContent?.outreachPageText && (
          <>
            <SanityPortableText content={informationContext.informationContent.outreachPageText} />
            <br />
          </>
        )}
        {externalContent ? (
          externalContent.map((content) => (
            <ExternalContentPreviewCard externalContent={content} key={content._id} />
          ))
        ) : (
          <Loader />
        )}
      </article>
    </CenteredContentContainer>
  );
};

export default StudentInformationListPage;
