import { getImageDimensions } from '@sanity/asset-utils';
import urlBuilder from '@sanity/image-url';

import { ImageObject } from '../types';

import { sanityClient } from '../utils/sanity';

interface Props {
  value: ImageObject;
  isInline: boolean;
  className?: string;
}

// Barebones lazy-loaded image component
// https://www.sanity.io/docs/portable-text-to-react#types
const SanityImageComponent = ({ value, isInline, className }: Props) => {
  const { width, height } = getImageDimensions(value);
  return (
    <figure className={className ?? ''}>
      <img
        src={urlBuilder(sanityClient)
          .image(value)
          .width(isInline ? 100 : 800)
          .fit('max')
          .auto('format')
          .url()}
        alt={value.alt || 'Figure'}
        loading="lazy"
        style={{
          // Display alongside text if image appears inside a block text span
          display: isInline ? 'inline-block' : 'block',

          // Avoid jumping around with aspect-ratio CSS property
          aspectRatio: width / height,
        }}
      />
      <figcaption>{value.figureText}</figcaption>
    </figure>
  );
};

export default SanityImageComponent;
