import { PortableText } from '@portabletext/react';
import { BlockContent } from '../types';

import SanityImageComponent from './SanityImageComponent';

interface Props {
  content: Array<BlockContent>;
}

const SanityPortableText = ({ content }: Props) => {
  return (
    <PortableText
      value={content}
      components={{
        types: {
          image: SanityImageComponent,
        },
        marks: {
          link: ({ value, children }) => {
            // External links open new tabs
            const target = (value?.href || '').startsWith('http') ? '_blank' : undefined;
            return (
              <a
                href={value?.href}
                target={target}
                rel={target === '_blank' ? 'noindex nofollow' : undefined}
              >
                {children}
              </a>
            );
          },
        },
      }}
    />
  );
};

export default SanityPortableText;
