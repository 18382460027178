import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import BasePage from './pages/BasePage';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import NewsArticlePage from './pages/NewsArticlePage';
import NewsPage from './pages/NewsPage';
import NotFoundPage from './pages/NotFoundPage';
import ProjectsPage from './pages/ProjectsPage';
import ProjectPage from './pages/ProjectPage';
import ProjectFaqsPage from './pages/ProjectFaqsPage';
import StudentInformationPage from './pages/StudentInfoPage';
import StudentInformationListPage from './pages/StudentInfoListPage';
import ContactForm from './components/ContactForm';
import PersonPage from './pages/PersonPage';
import OutreachPage from './pages/OutreachPage';

import { redirectSlugs } from './components/HiddenRedirectLinks';

import { CommonInformationProvider } from './contexts/commonInformationContext';

// TODO: Let Pascal edit. FTP to marsinstitute.no - or DNS rewrite .no to .blog? Update "to" e-mail in emailJS console.

const App = () => {
  return (
    <HelmetProvider>
      <CommonInformationProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<BasePage />}>
              <Route index element={<HomePage />} />
              <Route path="about" element={<AboutPage />} />
              <Route path="projects" element={<ProjectsPage />} />
              <Route path="projects/:slug" element={<ProjectPage />} />
              <Route path="projects/:slug/faqs" element={<ProjectFaqsPage />} />
              <Route path="news" element={<NewsPage />} />
              <Route path="news/:slug" element={<NewsArticlePage />} />
              <Route path="students" element={<StudentInformationListPage />} />
              <Route path="students/:slug" element={<StudentInformationPage />} />
              <Route path="team/:slug" element={<PersonPage />} />
              <Route path="outreach" element={<OutreachPage />} />
              <Route path="contact" element={<ContactForm />} />
              {Object.entries(redirectSlugs).map(([oldPageSlug, newPageSlug], i) => (
                <Route
                  key={`redirect-route-${i}`}
                  path={oldPageSlug}
                  element={<Navigate to={`${newPageSlug}`} />}
                />
              ))}
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </CommonInformationProvider>
    </HelmetProvider>
  );
};

export default App;
